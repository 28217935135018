import axios from 'axios'
import AuthToken from '@/auth/auth-token'

class Items {
  constructor () {
    const base = axios.create({
      baseURL: process.env.VUE_APP_BASE_API,
      withCredentials: false,
      headers: {
        'Content-type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
      }
    })
    this.items = base
  }

  async getSources () {
    await AuthToken.setAuth(this.items)
    return this.items({
      url: '/sources',
      method: 'GET'
    })
  }

  async getSourceRelsByPartyId (partyId) {
    await AuthToken.setAuth(this.items)
    return this.items({
      url: `/source/relationships/party/${partyId}`,
      method: 'GET'
    })
  }
}

export default new Items()