var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      staticClass: "my-card pt-4 my-2 mx-2",
      attrs: { "max-height": "520", hover: "" },
    },
    [
      _c(
        "section",
        {
          on: {
            click: function ($event) {
              $event.stopPropagation()
              _vm.showInfo = true
            },
          },
        },
        [
          _c(
            "v-row",
            { attrs: { justify: "center" } },
            [
              _c(
                "v-col",
                [
                  _c("ProductImage", {
                    attrs: { product: _vm.product, small: "" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            { attrs: { dense: "", justify: "center" } },
            [
              _c(
                "v-col",
                [
                  _c(
                    "v-card-subtitle",
                    {
                      staticClass:
                        "caption font-weight-bold text-center text-truncate",
                    },
                    [_vm._v(" " + _vm._s(_vm.product.description) + " ")]
                  ),
                  _c(
                    "v-card-text",
                    { staticClass: "pb-1" },
                    [
                      _c("v-divider", { staticClass: "mb-2" }),
                      _c(
                        "v-row",
                        { attrs: { dense: "", wrap: "", justify: "center" } },
                        _vm._l(_vm.dataFields, function (field, idx) {
                          return _c(
                            "v-col",
                            { key: idx, attrs: { cols: "6", align: "center" } },
                            [
                              _c(
                                "span",
                                { staticClass: "productInfo font-weight-bold" },
                                [_vm._v(" " + _vm._s(field.text) + " ")]
                              ),
                              _c("br"),
                              field.value === "pack_size"
                                ? _c("span", { staticClass: "caption" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.product.qty_per_case) +
                                        " / " +
                                        _vm._s(_vm.product.pack_size) +
                                        " "
                                    ),
                                  ])
                                : _c("span", { staticClass: "caption" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(field.prefix) +
                                        " " +
                                        _vm._s(
                                          field.type === "currency"
                                            ? `${_vm.$config.formatCurrency(
                                                _vm.product[`${field.value}`]
                                              )}`
                                            : _vm.product[`${field.value}`]
                                        ) +
                                        " "
                                    ),
                                  ]),
                            ]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-card-actions",
        { staticClass: "pb-4" },
        [
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              staticClass: "white--text",
              attrs: {
                small: "",
                rounded: "",
                color: "#2589BD",
                elevation: "0",
                disabled: _vm.loading || _vm.orderingDisabled,
              },
              on: {
                click: function ($event) {
                  return _vm.add(_vm.product)
                },
              },
            },
            [_vm._v(" add to cart ")]
          ),
          _c("v-spacer"),
        ],
        1
      ),
      _vm.showInfo
        ? _c("ProductInfo", {
            attrs: {
              product: _vm.product,
              loading: _vm.loading,
              disabled: _vm.loading || _vm.orderingDisabled,
              quantity: _vm.quantity,
            },
            on: {
              "update:quantity": function ($event) {
                _vm.quantity = $event
              },
              close: function ($event) {
                _vm.showInfo = false
              },
              add: _vm.add,
            },
            model: {
              value: _vm.showInfo,
              callback: function ($$v) {
                _vm.showInfo = $$v
              },
              expression: "showInfo",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }