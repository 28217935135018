export const fullWidthTable = {
    data () {
      return {
        containerWidth: 0,
        loading: false,
        page: 1,
        pageSize: 25,
        pageSizes: [25, 50, 100, 200],
        nonTableHeight: 273,
        serverItemsLength: 0,
        showDialog: false,
        tableSize: 0,
        windowHeight: 0
      }
    },
    filters: {
      stripExtraNonChar: (value) => {
        if (!value) return ''
        if (value.includes('.')) {
          value = value.split('.')
          const valLength = value.length
          value = value[(valLength - 1)]
        }
        if (value.includes('_')) {
          value = value.replace(/_/g, ' ')
        }
        value = value.toLowerCase()
        return value
      }
    },
    watch: {
      page: {
        handler (newValue) {
          if (newValue > 1) {
            this.from = (newValue * this.pageSize) - this.pageSize
            // this.tableChanged()
          } else {
            this.from = 0
            // this.tableChanged()
          }
        }
      },
      pageSize: {
        handler () {
          if (this.page > 1) {
            return this.page = 1
          }
          // this.tableChanged()
        }
      },
      nonTableHeight: {
        handler (newValue) {
          this.tableSize = this.windowHeight - newValue
        }
      },
      windowHeight: {
        handler () {
          this.tableSize = this.windowHeight - this.nonTableHeight
        }
      }
    },
    created () {
      this.tableSize = this.windowHeight - this.nonTableHeight
      window.addEventListener('resize', this.resize)
      this.resize()
    },
    methods: {
      previous () {
        if (this.page > 1) {
          this.page = this.page - 1
        }
      },
      next () {
        this.page = this.page + 1
      },
      resize () {
        this.windowHeight = window.innerHeight
      },
    }
  }
  