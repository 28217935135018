<template>
  <v-container fluid class="ma-0 pa-0">
    <v-toolbar flat class="grey lighten-3">
      <v-icon class="mr-2">mdi-package</v-icon>
      <v-toolbar-title>All Products</v-toolbar-title>
      <v-divider class="mx-4" inset vertical></v-divider>
      <v-btn
        class="mr-4 white--text"
        color="primary"
        @click="showProductModal()">
        <v-icon left>mdi-note-edit-outline</v-icon>
        Create
      </v-btn>
      <v-btn
        color="accent"
        class="mr-4 white--text"
        @click.stop="uploadModal = true">
        <v-icon left>mdi-file-upload-outline</v-icon>
        Import
      </v-btn>
      <v-btn
        color="main"
        class="white--text"
        @click.stop="bulkImageUploadModal = true">
        <v-icon left>mdi-file-upload-outline</v-icon>
        Bulk Image
      </v-btn>
      <v-col cols="4" class="d-flex ml-auto">
        <ProductSearchFilters v-model="searchParams" />
        <v-btn
          :loading="loading"
          :disabled="loading"
          depressed
          height="40"
          color="accent"
          style="border-top-left-radius: 0px; border-bottom-left-radius:0px; border: 1px solid #9e9e9e; border-left: 0px;"
          @click.stop="page > 1 ? page = 1 : getProducts()">
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
      </v-col>
    </v-toolbar>
    <v-data-table
      :height="tableSize"
      :headers="headers"
      :items.sync="productItems"
      :loading="loading"
      loading-text="Loading... Please wait"
      hide-default-footer
      :items-per-page.sync="pageSize"
      :page.sync="page"
      item-key="id"
      fixed-header
      dense>

      <template v-slot:[`item.image_src`]="{ item }">
        <div style="cursor: pointer" 
          v-if="item.seasonal || item.image_url" 
          @click="showProductInfo(item)">
          <ProductImage :product="item" small />
        </div>
      </template>

      <template v-slot:[`item.source`]="{ item }">
        <span>{{ $store.getters.getSourceName(item.source) }}</span>
      </template>

      <template v-slot:[`item.each_cost`]="{ item }">
        <span v-if="item.each_cost">${{ item.each_cost }}</span>
      </template>

      <template v-slot:[`item.sale_price`]="{ item }">
        <span v-if="item.sale_price">${{ item.sale_price }}</span>
      </template>

      <template v-slot:[`item.cost`]="{ item }">
        <span v-if="item.cost">${{ item.cost }}</span>
      </template>

      <template v-slot:[`item.edit`]="{ item }">
        <v-btn
          icon
          @click="showProductModal(item)">
          <v-icon>mdi-pencil-outline</v-icon>
        </v-btn>
        <!-- <ProductActions :product="item" @refresh="getProducts()" /> -->
      </template>
      <template v-slot:footer>
        <PagingFooter
          :pageSizes="pageSizes"
          :pageSize.sync="pageSize"
          :page="page"
          :disablePrevious="disablePrevious"
          :disableNext="disableNext" 
          @previous="previous" 
          @next="next">
        </PagingFooter>
      </template>
    </v-data-table>
    <v-card class="mx-auto" max-width="300">
      <ProductView
        v-if="selectedProduct || productModal"
        :value.sync="productModal"
        :selectedProduct="selectedProduct"
        @closeAndRefresh="getProducts">
      </ProductView>
    </v-card>
    <ProductUpload
      v-if="uploadModal"
      v-model="uploadModal"
      @closeUpload="closeUploadModal">
    </ProductUpload>
    <BulkImageUpload
      v-if="bulkImageUploadModal"
      v-model="bulkImageUploadModal"
      @closeBulkImageUpload="closeBulkImageUploadModal">
    </BulkImageUpload>
    <ProductInfo
      v-if="showInfo" 
      v-model="showInfo"
      :product="selectedProduct"
      @close="closeProductInfo"
      readonly>
    </ProductInfo>
  </v-container>
</template>
<script>
// api
import Products from '@/api/product'
// mixins
import { fullWidthTable } from '@/mixins/table'
import { displayAlert } from '@/mixins/alert'
// components
import ProductImage from '@/components/products/ProductImage.vue'
import PagingFooter from '@/components/PagingFooter.vue'
const ProductView = () => import('@/views/ProductView.vue')
import ProductSearchFilters from '@/components/products/ProductSearchFilters.vue'
const ProductUpload = () => import('@/components/products/ProductUpload.vue')
const BulkImageUpload = () => import('@/components/products/BulkImageUpload.vue')
const ProductInfo = () => import('@/components/products/ProductInfo.vue')
export default {
  name: 'ProductsTable',
  data() {
    return {
      showInfo: false,
      loading: false,
      productModal: false,
      uploadModal: false,
      bulkImageUploadModal: false,
      selectedProduct: null,
      searchParams: {},
      productItems: [],
      headerProps: {
        sortable: true,
        filterable: false,
        class: 'accent white--text text-no-wrap'
      },
      baseHeaders: [
        { sortable: false },
        { text: 'Image', value: 'image_src', sortable: false },
        { text: 'Source', value: 'source' },
        { text: 'Description', value: 'description' },
        { text: 'Item Id', value: 'item_id' },
        { text: 'UPC', value: 'upc' },
        { text: 'Qty/Case', value: 'qty_per_case' },
        { text: 'Pack Size', value: 'pack_size' },
        { text: 'Each Cost', value: 'each_cost' },
        { text: 'Unit Cost', value: 'sale_price' },
        { text: 'Case Cost', value: 'cost' },
        { text: 'Seasonal', value: 'seasonal' },
        { value: 'edit', sortable: false }
      ],
      formatFields: [
        'cost', 'each_cost', 'sale_price'
      ]
    }
  },
  mixins: [fullWidthTable, displayAlert],
  components: { PagingFooter, ProductView, ProductSearchFilters, ProductImage, ProductUpload, ProductInfo, BulkImageUpload },
  watch: {
    page: {
      handler(newValue) {
        if (newValue) this.getProducts()
      }
    },
    pageSize: {
      handler() {
        if (this.page > 1) {
          this.page = 1
        } else {
          this.getProducts()
        }
      }
    },
    productModal: {
      handler(newValue){
        if(!newValue) {
          this.selectedProduct = null
        }
      }
    }
  },
  async created() {
    this.nonTableHeight = 267.5
    if (sessionStorage.product_search_filters && this.activeSearch) {
      this.loading = true
      this.searchParams = JSON.parse(sessionStorage.product_search_filters)
    }
    await this.getProducts()
  },
  computed: {
    activeSearch () {
      if (sessionStorage.product_search_filters) {
        const stored = JSON.parse(sessionStorage.product_search_filters)
        return Object.keys(stored).some(key => Boolean(stored[key]))
      }
      return false
    },
    headers() {
      return this.baseHeaders.map(header => {
        return { ...this.headerProps, ...header }
      })
    },
    limit() {
      return this.pageSize
    },
    offset() {
      return (this.page - 1) * this.pageSize
    },
    disablePrevious() {
      return this.page === 1
    },
    disableNext() {
      return this.productItems.length < this.pageSize
    }
  },
  methods: {
    showProductInfo(product) {
      this.selectedProduct = product
      this.showInfo = true
    },
    closeProductInfo() {
      this.showInfo = false
      this.selectedProduct = null
    },
    async getProducts() {
      this.selectedProduct = null
      this.productModal = false
      this.loading = true
      const params = this.buildSearchParams()
      let products = []
      try {
        const { data } = await Products.getProducts(params)
        if (data?.length > 0) {
          products = data.map(product => this.formatProduct(product))
        }
        this.productItems = products
      } catch (e) {
        this.handleError(e)
      } finally {
        this.loading = false
      }
    },
    formatProduct(product) {
      for (const field of this.formatFields) {
        product[field] = this.$config.formatCurrency(product[field])
      }
      return product
    },
    buildSearchParams () {
      return {
        offset: this.offset,
        limit: this.limit,
        ...this.searchParams
      }
    },
    showProductModal(selected) {
      if(selected) {
        this.selectedProduct = selected
      }
      this.productModal = true
    },
    closeUploadModal() {
      this.uploadModal = false
    },
    closeBulkImageUploadModal() {
      this.bulkImageUploadModal = false
    }
  }
}
</script>
    