<template>
  <div class="image-container">
    <v-img
      v-if="imageUrl"
      :src="imageUrl"
      contain
      class="ma-auto"
      :max-height="small ? '100px' : 500"
      :max-width="small ? '100px' : 'auto'">
    </v-img>
    <div v-else class="placeholder-container">
      <span style="display: block; margin: auto">
        <small>Image coming soon</small><br>
      </span>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ProductImage',
  props: {
    product: Object,
    small: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    imageUrl() {
      if (this.product?.seasonal && this.product?.upc && !this.product.image_url) {
        let { upc } = this.product
        if (upc.length > 11) {
          upc = this.getNormalizedUPC(upc)
        }
        return `https://sbpv3-abs-images.s3.amazonaws.com/${upc}.gif`
      }
      return this.product.image_url
    }
  },
  methods: {
    getNormalizedUPC(upc) {
      const diff = (upc.length - 11)
      const start = (diff - 1)
      const end = upc.length - (Math.sign(start + 1))
      return upc.substring(start, end)
    }
  }
}
</script>
<style scoped>
.image-container {
  display: grid;
  justify-content: center;
  min-height: 100px;
}

.placeholder-container {
  display: grid;
  justify-content: center;
  align-content: center;
  height: 100px;
  padding: 15px;
  margin: auto;
  text-align: center;
  border: 1px solid #eee; 
}
</style>