export const displayHelpers = {
    computed: {
      title () {
        switch (this.$vuetify.breakpoint.name) {
          case 'xl': return 'text-capitalize text-h5 accent--text font-weight-light';
          case 'lg': return 'text-capitalize text-h5 accent--text font-weight-light';
          case 'md': return 'text-capitalize text-h6 accent--text font-weight-light';
          default: return '5em';
        }
      },
  
      label () {
        switch (this.$vuetify.breakpoint.name) {
          case 'xl': return 'text-capitalize text-body-1 font-weight-medium';
          case 'lg': return 'text-capitalize text-body-2 font-weight-medium';
          case 'md': return 'text-capitalize text-body-2 font-weight-medium';
          default: return '5em';
        }
      },
  
      body () {
        switch (this.$vuetify.breakpoint.name) {
          case 'xl': return 'text-capitalize text-body-1 font-weight-light';
          case 'lg': return 'text-capitalize text-body-2 font-weight-light';
          case 'md': return 'text-capitalize text-body-2 font-weight-light';
          default: return '5em';
        }
      },
  
      table () {
        switch (this.$vuetify.breakpoint.name) {
          case 'xl': return 'text-body-1 font-weight-normal';
          case 'lg': return 'text-body-1 font-weight-normal';
          case 'md': return 'text-body-2 font-weight-normal';
          default: return '5em';
        }
      },
  
      floatingButton () {
        return `
          bottom: 75px;
          right: 25px; 
        `
      },
  
      tableButtons () {
        return `
          height: 25px;
          width: 25px;
        `
      },
    }
  }