export const utils = {
  methods: {
    async getAllSettled (promises, returnErrors = false) {
      const rejected = []
      const results = await Promise.allSettled(promises)
      const fulfilled = results.flatMap(result => {
        if (result.status === 'rejected') {
          rejected.push(result)
          return []
        }
        return result.value
      })
      if (rejected.length > 0) console.log('Rejected promises:', rejected)
      if (returnErrors) {
        return { fulfilled, rejected }
      }
      return fulfilled
    },
    getUniqueByKey (arr, key) {
      if (arr?.length > 0) {
        const values = arr.map(item => item[key])
        const uniqueValues = Array.from(new Set([...values]))
        return uniqueValues.sort()
      }
      return []
    },
    sortByKey (arr, key) {
      arr.sort((a, b) => {
        if (a && b) {
          if (a[key] < b[key]) return -1
          else if (a[key] > b[key]) return 1
          else return 0
        }
      })
      return arr
    },
    setProductPrices(product, price_key) {
      if (product.seasonal && product.price_data && price_key) {
        const { cost, sale_price } = product
        product.cost = product.price_data[`${price_key}_case_cost`] || cost
        product.sale_price = product.price_data[`${price_key}_unit_cost`] || sale_price
      }
      return product
    }
  }
}