<template>
  <v-dialog :value="value" persistent width="700" @click:outside="closeDialog()">
    <v-card>
      <v-card-title>Select Event to Export
        <v-spacer></v-spacer>
        <v-btn 
          icon
          @click.stop="closeDialog()">
          <v-icon>mdi-close</v-icon>
        </v-btn> 
      </v-card-title>
      <v-card-text class="pb-0">
        <v-form ref="form" lazy-validation>
          <v-container>
            <v-row>
              <v-select
                v-model="selectedEvent"
                label="Event"
                :items="events"
                :menu-props="{ offsetY: true }"
                item-text="name"
                return-object
                :error-messages="!selectedEvent ? ['Please select an event'] : []"
                outlined
                dense>
              </v-select>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="grey darken-1"
          text
          @click="closeDialog()">
          Cancel
        </v-btn>
        <v-btn
          color="primary"
          :disabled="!selectedEvent"
          @click="exportEventOrders">
          Confirm
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { displayHelpers } from '@/mixins/display-helpers'
import { notification } from '@/mixins/notification'
import { displayAlert } from '@/mixins/alert'
import ProductEvent from '@/api/product-event'

export default {
  name: 'EventOrderExportView',
  mixins: [displayHelpers, displayAlert, notification],
  props: ['value'],
  data() {
    return {
      loading: false,
      orderItems: [],
      selectedEvent: null,
      events: [],
    }
  },
  async created() {
    this.loading = true
    await this.getEvents()
    this.loading = false
  },
  methods: {
    closeDialog() {
      this.$emit('closeEventOrderExportView')
    },
    async getEvents() {
      try {
        const { data } = await ProductEvent.getList(0,200)
        if (data) {
          this.events = data
        }
      } catch (err) {
        this.handleError(err)
      }
    },
    async exportEventOrders() {
      this.closeDialog()

      try {
        this.emitAlert(true, 'success', 'Downloading event order rollup')
        const res =  await ProductEvent.exportOrders(this.selectedEvent.id)
        if (res?.data) {
          location.href = res.data
        }
      } catch (err) {
        this.handleError(err)
      }
    }
  }
}
</script>
<style>
.header {
  color: white;
  background: #385F73;
}
</style>
