var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticStyle: {
        "border-top": "thin solid rgba(0, 0, 0, 0.12)",
        "margin-right": "14px",
      },
    },
    [
      _c(
        "v-row",
        {
          staticStyle: {
            padding: "0 0 2px 0",
            "font-size": "12px",
            height: "58px",
          },
          attrs: { align: "center", dense: "", "no-gutters": "" },
        },
        [
          _c("v-spacer"),
          _c(
            "div",
            {
              staticStyle: { margin: "auto 34px auto 0", "font-size": "12px" },
            },
            [_vm._v(" Rows per page: ")]
          ),
          _c(
            "v-col",
            {
              staticStyle: { margin: "0 22px 0 0", padding: "0" },
              attrs: { cols: "auto" },
            },
            [
              _c("v-select", {
                staticStyle: {
                  margin: "0px 0 13px 0",
                  "font-size": "12px",
                  width: "auto",
                  "max-width": "60px",
                },
                attrs: {
                  "hide-details": "",
                  items: _vm.pageSizes,
                  value: _vm.pageSize,
                },
                on: {
                  change: function ($event) {
                    return _vm.$emit("update:pageSize", $event)
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              staticStyle: {
                display: "grid",
                "grid-auto-flow": "column",
                "grid-auto-columns": "auto",
                "align-content": "center",
              },
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    "font-size": "12px",
                    margin: "auto 18px auto 20px",
                  },
                },
                [_vm._v(" Page " + _vm._s(_vm.page) + " ")]
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    display: "grid",
                    "grid-auto-flow": "column",
                    "grid-auto-columns": "auto",
                    "align-content": "center",
                  },
                },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mx-2",
                      attrs: { icon: "", disabled: _vm.disablePrevious },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("previous")
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("mdi-chevron-left")])],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mx-2",
                      attrs: { icon: "", disabled: _vm.disableNext },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("next")
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("mdi-chevron-right")])],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }