<template>
    <v-container fluid class="ma-0 pa-0">
        <OrdersTable/>
    </v-container>
</template>
<script>
// mixins
import { fullWidthTable } from '@/mixins/table'
import { displayAlert } from '@/mixins/alert'
// components
import OrdersTable from '@/components/product-orders/OrdersTable.vue'
export default {
    name: 'OrderHistoryView',
    data() {
        return {
            hideDetails: false,
            loading: false,
            productOrders: []
        }
    },
    mixins: [fullWidthTable, displayAlert],
    components: { OrdersTable },
    watch: {},
    computed: {},
    methods: {}
}
</script>
<style>
.v-application--is-ltr .v-data-footer__pagination {
    margin-left: auto;
}
</style>