import axios from 'axios'
import AuthToken from '@/auth/auth-token'

class ProductGroup {
  constructor () {
    const base = axios.create({
      // using base items api
      baseURL: process.env.VUE_APP_BASE_API,
      withCredentials: false,
      headers: {
        'Content-type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
      }
    })
    this.productGroup = base
  }

  async getEventGroups (product_event_id, offset = 0, limit = 50, params = {}) {
    await AuthToken.setAuth(this.productGroup)
    return this.productGroup({
      url: `/product_groups`,
      method: 'GET',
      params: { product_event_id, offset, limit, ...params }
    })
  }

  async get (id) {
    await AuthToken.setAuth(this.productGroup)
    return this.productGroup({
      url: `/product_group/${id}`,
      method: 'GET'
    })
  }

  async create (payload) {
    await AuthToken.setAuth(this.productGroup)
    return this.productGroup({
      url: `/product_group`,
      method: 'POST',
      data: payload
    })
  }

  async update (id, payload) {
    await AuthToken.setAuth(this.productGroup)
    return this.productGroup({
      url: `/product_group/${id}`,
      method: 'PUT',
      data: payload
    })
  }

  async delete (id) {
    await AuthToken.setAuth(this.productGroup)
    return this.productGroup({
      url: `/product_group/${id}`,
      method: 'DELETE'
    })
  }
}

export default new ProductGroup()