import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import moment from 'moment'

import '@/assets/css/main.css'

// local config
import config from './config/product-catalog-config'

// Sentry
// import * as Sentry from "@sentry/vue";
// import { Integrations } from "@sentry/tracing";

// Auth0
import { domain, clientId, audience } from './auth/auth0-config.js'
import { Auth0Plugin } from './auth/auth0-plugin'

Vue.use(Auth0Plugin, {
  domain,
  clientId,
  audience,
  onRedirectCallback: (appState) => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    )
  }
})

// const appEnv = process.env.VUE_APP_ENV

// if (appEnv !== 'local') {
//   Sentry.init({
//     Vue: Vue,
//     environment: appEnv,
//     // TODO - find correct dsn from Sentry
//     // dsn: 'https://337b6e7d3f75455cb9eef1fe2ef805da@o461793.ingest.sentry.io/5667214',
//     integrations: [new Integrations.BrowserTracing()],
//     tracingOptions: {
//       trackComponents: true
//     },
//     logErrors: true,
//     tracesSampleRate: 1.0
//   })
// }

Vue.config.productionTip = false

Vue.prototype.$config = config.CONSTANTS
Vue.prototype.moment = moment

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
