var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "ma-0 pa-0", attrs: { fluid: "" } },
    [
      _c(
        "v-card",
        [
          _c(
            "v-tabs",
            {
              attrs: { color: "primary", centered: "", grow: "" },
              model: {
                value: _vm.tab,
                callback: function ($$v) {
                  _vm.tab = $$v
                },
                expression: "tab",
              },
            },
            [
              _vm._l(_vm.tabs, function (item) {
                return _c(
                  "v-tab",
                  { key: item.tab },
                  [
                    _c("v-icon", { attrs: { left: "" } }, [
                      _vm._v(_vm._s(item.icon)),
                    ]),
                    _vm._v(" " + _vm._s(item.title) + " "),
                  ],
                  1
                )
              }),
              _c(
                "v-tabs-items",
                {
                  staticStyle: { "border-top": "1px solid #ddd" },
                  model: {
                    value: _vm.tab,
                    callback: function ($$v) {
                      _vm.tab = $$v
                    },
                    expression: "tab",
                  },
                },
                _vm._l(_vm.tabs, function (item) {
                  return _c(
                    "v-tab-item",
                    { key: item.tab },
                    [_c(item.content, { tag: "component" })],
                    1
                  )
                }),
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }