export const displayAlert = {
    data () {
      return {
        alert: false,
        alertType: 'warning',
        alertMessage: '',
        errors: [],
        messages: [],
        successes: [],
        timeout: -1
      }
    },
    watch: {
      errors: {
        handler (newValue) {
          if (newValue) {
            if (this.errors.length >= 1) {
              this.emitAlert(true, 'warning', 'Please correct the following errors: ', this.errors, this.timeout)
            }
          }
        }
      },
      successes: {
        handler (newValue) {
          if (newValue) {
            if (this.successes.length >= 1) {
              this.emitAlert(true, 'success', 'Success: ', this.successes, this.timeout)
            }
          }
        }
      }
    },
    methods: {
      emitAlert (showAlert, alertType, alertMessage, messages, timeout) {
        const payload = {
          alert: showAlert,
          alertType: alertType,
          alertMessage: alertMessage,
          messages: messages,
          timeout: timeout
        }
        this.$root.$emit('alertdialog', payload)
      },
      resetAlert () {
        this.errors.length = 0
        this.successes.length = 0
        const payload = {
          alert: false,
          alertType: 'success',
          alertMessage: '',
          messages: []
        }
        this.$root.$emit('alertdialog', payload)
      },
      handleRejected (rejected) {
        const errs = rejected.map(e => e.reason?.response?.data?.message || e.reason.message)
        this.emitAlert(true, 'warning', `The following errors have occurred: ${errs.join(', ')}`)
      },
      handleError (err) {
        if (Array.isArray(err)) {
          return this.handleRejected(err)
        }
        console.error(err)
        const message = err?.response?.data?.message || err.message
        this.emitAlert(true, 'warning', message)
      }
    }
  }
  