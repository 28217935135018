export const notification = {
    methods: {
      notify (type, message) {
        const payload = {
          type: type,
          message: message
        }
        this.$root.$emit('notification', payload)
      },
  
      showLoader (message, show) {
        const payload = {
          message: message,
          show: show
        }
        this.$root.$emit('loader', payload)
      },
  
      showErrors (payload) {
        this.$root.$emit('show-errors', payload)
      }
    }
  }
  