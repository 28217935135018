<template>
    <v-select
      v-model="selectedFilters"
      label="Search Filters"
      :items="filters"
      item-text="name"
      item-value="key"
      background-color="input"
      :menu-props="{ offsetY: true, maxHeight: '600' }"
      style="border-top-right-radius: 0px; border-bottom-right-radius: 0px; border-right: 0px;"
      multiple
      outlined
      hide-details
      small-chips
      dense>
      <template v-slot:selection="{ attrs, item }">
        <v-chip
          v-bind="attrs"
          small>
          {{ getFilterText(item) }}
          <v-icon
            small
            right
            @click="removeFilter(item.key, true)">
            $delete
          </v-icon>
        </v-chip>
      </template>
      <template v-slot:item="{ item, attrs }">
        <v-list-item>
          <v-list-item-content>
            <v-autocomplete 
              v-if="item.key === 'source'"
              v-model="params.source"
              :items="sources"
              item-text="source_name"
              item-value="source_id"
              :label="item.name"
              hide-details
              background-color="input"
              dense
              clearable
              @change="handleInputValue(item)"
              @click:clear="removeFilter(item.key)"
              outlined>
            </v-autocomplete>
            <v-checkbox
              v-else-if="item.type === 'boolean'"
              v-model="params[`${item.key}`]"
              :label="item.name"
              class="pl-2 pb-0 mt-0"
              @change="handleInputValue(item)">
            </v-checkbox>
            <v-text-field
              v-else
              v-model="params[`${item.key}`]"
              :label="item.name"
              hide-details
              background-color="input"
              :prefix="item.prefix || null"
              :type="item.type"
              dense
              clearable
              @keyup.stop="attrs.inputValue ? null : handleInputValue(item)"
              @click:clear="removeFilter(item.key)"
              outlined>
            </v-text-field>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-select>
</template>
<script>
  // third-party
import { debounce } from 'lodash'

export default {
    data () {
      return {
        selectedFilters: [],
        filters: [
          { name: 'Description', key: 'description', type: 'text' },
          { name: 'Source', key: 'source', type: 'select' },
          { name: 'Item Id', key: 'item_id', type: 'text' },
          { name: 'UPC', key: 'upc', type: 'text' },
          { name: 'Seasonal', key: 'seasonal', type: 'boolean'}
        ],
        params: {
          source: '',
          item_id: '',
          seasonal: null
        }
      }
    },
    name: 'ProductSearchFilters',
    props: {
      value: Object
    },
    watch: {
      activeFilters: {
        handler: debounce(function (newValue) {
          if (newValue) {
            this.updateSearchFilters()
          }
        }, 400),
        deep: true
      }
    },
    beforeCreate () {
      this.$store.dispatch('setSources')
    },
    created () {
      if (this.value) {
        this.params = { ...this.params, ...this.value }
        const activeKeys = Object.keys(this.params).filter(key => Boolean(this.params[key]))
        if (activeKeys.length > 0) this.selectedFilters.push(...activeKeys)
      }
    },
    computed: {
      activeFilters () {
        return Object.keys(this.params).filter(this.isActive)
      },
      filtersObj() {
        return this.activeFilters.reduce((obj, key) => {
          obj[key] = this.params[key]
          return obj
        }, {})
      },
      sources() {
        return this.$store.getters.sources
      }
    },
    methods: {
      isActive (key) {
        if (this.isSelected(key)) {
          const value = this.params[key]
          if (key === 'seasonal') {
            return (value !== null)
          }
          return Array.isArray(value) ? (value.length > 0) : Boolean(value)
        }
        return false
      },
      getFilterText (item) {
        return `${item.text || item.name}: "${this.params[item.key]}"`
      },
      removeFilter (key, clearValue = false) {
        const index = this.selectedFilters.indexOf(key)
        if (index > -1) {
          this.selectedFilters.splice(index, 1)
          if (clearValue) this.params[key] = null
        }
      },
      handleInputValue: debounce(function (item) {
        if (Boolean(this.params[item.key]) && !this.isSelected(item.key)) {
          this.selectedFilters.push(item.key)
        }
      }, 300),
      handleChangedArray (newValue, key) {
        if ((newValue.length > 0) && !this.isSelected(key)) {
          this.selectedFilters.push(key)
        } else if ((newValue.length === 0) && this.isSelected(key)) {
          this.removeFilter(key)
        }
      },
      isSelected (key) {
        return this.selectedFilters.includes(key)
      },
      updateSearchFilters () {
        this.$emit('input', this.filtersObj)
        if (this.activeFilters.length > 0) {
          sessionStorage.setItem('product_search_filters', JSON.stringify(this.filtersObj))
        } else {
          sessionStorage.removeItem('product_search_filters')
        }
      }      
    }
}
</script>