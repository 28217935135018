export const partyHelpers = {
    methods: {
      formatStore (party) {
        let display_name = party.party_name || party.name
        if (party.attributes) {
          let attrVal = party.attributes['WHOLESALER_STORE_NUMBER'] || ''
          if (attrVal) {
            attrVal = `#${attrVal}`
          }
          display_name = `${attrVal} ${display_name}`
        }
        party.name = display_name
        return party
      }
    }
  }