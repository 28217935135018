var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "ma-0 pa-0", attrs: { fluid: "" } },
    [
      _c(
        "v-toolbar",
        { staticClass: "grey lighten-3", attrs: { flat: "" } },
        [
          _c("v-icon", { staticClass: "mr-2" }, [_vm._v("mdi-package")]),
          _c("v-toolbar-title", [_vm._v("All Products")]),
          _c("v-divider", {
            staticClass: "mx-4",
            attrs: { inset: "", vertical: "" },
          }),
          _c(
            "v-btn",
            {
              staticClass: "mr-4 white--text",
              attrs: { color: "primary" },
              on: {
                click: function ($event) {
                  return _vm.showProductModal()
                },
              },
            },
            [
              _c("v-icon", { attrs: { left: "" } }, [
                _vm._v("mdi-note-edit-outline"),
              ]),
              _vm._v(" Create "),
            ],
            1
          ),
          _c(
            "v-btn",
            {
              staticClass: "mr-4 white--text",
              attrs: { color: "accent" },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  _vm.uploadModal = true
                },
              },
            },
            [
              _c("v-icon", { attrs: { left: "" } }, [
                _vm._v("mdi-file-upload-outline"),
              ]),
              _vm._v(" Import "),
            ],
            1
          ),
          _c(
            "v-btn",
            {
              staticClass: "white--text",
              attrs: { color: "main" },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  _vm.bulkImageUploadModal = true
                },
              },
            },
            [
              _c("v-icon", { attrs: { left: "" } }, [
                _vm._v("mdi-file-upload-outline"),
              ]),
              _vm._v(" Bulk Image "),
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "d-flex ml-auto", attrs: { cols: "4" } },
            [
              _c("ProductSearchFilters", {
                model: {
                  value: _vm.searchParams,
                  callback: function ($$v) {
                    _vm.searchParams = $$v
                  },
                  expression: "searchParams",
                },
              }),
              _c(
                "v-btn",
                {
                  staticStyle: {
                    "border-top-left-radius": "0px",
                    "border-bottom-left-radius": "0px",
                    border: "1px solid #9e9e9e",
                    "border-left": "0px",
                  },
                  attrs: {
                    loading: _vm.loading,
                    disabled: _vm.loading,
                    depressed: "",
                    height: "40",
                    color: "accent",
                  },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      _vm.page > 1 ? (_vm.page = 1) : _vm.getProducts()
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-magnify")])],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("v-data-table", {
        attrs: {
          height: _vm.tableSize,
          headers: _vm.headers,
          items: _vm.productItems,
          loading: _vm.loading,
          "loading-text": "Loading... Please wait",
          "hide-default-footer": "",
          "items-per-page": _vm.pageSize,
          page: _vm.page,
          "item-key": "id",
          "fixed-header": "",
          dense: "",
        },
        on: {
          "update:items": function ($event) {
            _vm.productItems = $event
          },
          "update:itemsPerPage": function ($event) {
            _vm.pageSize = $event
          },
          "update:items-per-page": function ($event) {
            _vm.pageSize = $event
          },
          "update:page": function ($event) {
            _vm.page = $event
          },
        },
        scopedSlots: _vm._u(
          [
            {
              key: `item.image_src`,
              fn: function ({ item }) {
                return [
                  item.seasonal || item.image_url
                    ? _c(
                        "div",
                        {
                          staticStyle: { cursor: "pointer" },
                          on: {
                            click: function ($event) {
                              return _vm.showProductInfo(item)
                            },
                          },
                        },
                        [
                          _c("ProductImage", {
                            attrs: { product: item, small: "" },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              },
            },
            {
              key: `item.source`,
              fn: function ({ item }) {
                return [
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.$store.getters.getSourceName(item.source))
                    ),
                  ]),
                ]
              },
            },
            {
              key: `item.each_cost`,
              fn: function ({ item }) {
                return [
                  item.each_cost
                    ? _c("span", [_vm._v("$" + _vm._s(item.each_cost))])
                    : _vm._e(),
                ]
              },
            },
            {
              key: `item.sale_price`,
              fn: function ({ item }) {
                return [
                  item.sale_price
                    ? _c("span", [_vm._v("$" + _vm._s(item.sale_price))])
                    : _vm._e(),
                ]
              },
            },
            {
              key: `item.cost`,
              fn: function ({ item }) {
                return [
                  item.cost
                    ? _c("span", [_vm._v("$" + _vm._s(item.cost))])
                    : _vm._e(),
                ]
              },
            },
            {
              key: `item.edit`,
              fn: function ({ item }) {
                return [
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "" },
                      on: {
                        click: function ($event) {
                          return _vm.showProductModal(item)
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("mdi-pencil-outline")])],
                    1
                  ),
                ]
              },
            },
            {
              key: "footer",
              fn: function () {
                return [
                  _c("PagingFooter", {
                    attrs: {
                      pageSizes: _vm.pageSizes,
                      pageSize: _vm.pageSize,
                      page: _vm.page,
                      disablePrevious: _vm.disablePrevious,
                      disableNext: _vm.disableNext,
                    },
                    on: {
                      "update:pageSize": function ($event) {
                        _vm.pageSize = $event
                      },
                      "update:page-size": function ($event) {
                        _vm.pageSize = $event
                      },
                      previous: _vm.previous,
                      next: _vm.next,
                    },
                  }),
                ]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
      }),
      _c(
        "v-card",
        { staticClass: "mx-auto", attrs: { "max-width": "300" } },
        [
          _vm.selectedProduct || _vm.productModal
            ? _c("ProductView", {
                attrs: {
                  value: _vm.productModal,
                  selectedProduct: _vm.selectedProduct,
                },
                on: {
                  "update:value": function ($event) {
                    _vm.productModal = $event
                  },
                  closeAndRefresh: _vm.getProducts,
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm.uploadModal
        ? _c("ProductUpload", {
            on: { closeUpload: _vm.closeUploadModal },
            model: {
              value: _vm.uploadModal,
              callback: function ($$v) {
                _vm.uploadModal = $$v
              },
              expression: "uploadModal",
            },
          })
        : _vm._e(),
      _vm.bulkImageUploadModal
        ? _c("BulkImageUpload", {
            on: { closeBulkImageUpload: _vm.closeBulkImageUploadModal },
            model: {
              value: _vm.bulkImageUploadModal,
              callback: function ($$v) {
                _vm.bulkImageUploadModal = $$v
              },
              expression: "bulkImageUploadModal",
            },
          })
        : _vm._e(),
      _vm.showInfo
        ? _c("ProductInfo", {
            attrs: { product: _vm.selectedProduct, readonly: "" },
            on: { close: _vm.closeProductInfo },
            model: {
              value: _vm.showInfo,
              callback: function ($$v) {
                _vm.showInfo = $$v
              },
              expression: "showInfo",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }