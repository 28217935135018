import axios from 'axios'
import AuthToken from '@/auth/auth-token'

class PartyRelationship {
  constructor () {
    const base = axios.create({
      baseURL: process.env.VUE_APP_CUSTOMER_API,
      withCredentials: false,
      headers: {
        'Content-type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
      }
    })
    this.partyRelationship = base
  }

  async getUpstreamRelationships (payload) {
    await AuthToken.setAuth(this.partyRelationship, {
      audience: process.env.VUE_APP_CUSTOMER_API_AUDIENCE
    })
    return this.partyRelationship({
      url: `v1/party_relationship/upstream_search`,
      method: 'POST',
      data: payload
    })
  }
}

export default new PartyRelationship()
