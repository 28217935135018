import Vue from 'vue'
import VueRouter from 'vue-router'
import ProductsView from '../views/ProductsView.vue'
import AdminView from '../views/AdminView.vue'
import OrderHistoryView from '../views/OrderHistoryView'
const ProductEventView = () => import('@/views/ProductEventView.vue')

// authentication guard
import { authGuard } from '@/auth/authGuard'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: ProductsView,
    meta: {
      requiresAuth: true,
      title: 'Product Catalog'
    }
  },
  {
    path: '/products',
    name: 'products',
    component: ProductsView,
    meta: {
      title: 'Product Catalog',
      requiresAuth: true
    }
  },
  {
    path: '/orders',
    name: 'orders',
    component: OrderHistoryView,
    meta: {
      title: 'Order History',
      requiresAuth: true
    }
  },
  {
    path: '/admin',
    name: 'admin',
    component: AdminView,
    meta: {
      requiresAuth: true,
      adminOnly: true,
      title: 'Product Catalog Admin'
    }
  },
  {
    path: '/admin/:tab',
    name: 'adminTab',
    component: AdminView,
    meta: {
      requiresAuth: true,
      adminOnly: true,
      title: 'Product Catalog Admin'
    }
  },
  {
    path: '/admin/event/new',
    component: ProductEventView,
    meta: {
      requiresAuth: true,
      adminOnly: true,
      title: 'Product Event'
    }
  },
  {
    path: '/admin/event/:id',
    name: 'ProductEvent',
    component: ProductEventView,
    meta: {
      requiresAuth: true,
      adminOnly: true,
      title: 'Product Event'
    },
    props: true
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeResolve(async (to, from, next) => {
  to.meta.referrer = from.name
  if (to.meta) {
    // Use the route's "meta.title" value to assign the page's title
    if (to.meta.title) document.title = `JBG | ${to.meta.title}`
    // For routes requiring authentication ( has "meta.requiresAuth" property )
    if (to.meta.requiresAuth === true) {
      return authGuard(to, from, next)
    }
    return next()
  } else {
    return next()
  }
})

export default router
