var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "image-container" },
    [
      _vm.imageUrl
        ? _c("v-img", {
            staticClass: "ma-auto",
            attrs: {
              src: _vm.imageUrl,
              contain: "",
              "max-height": _vm.small ? "100px" : 500,
              "max-width": _vm.small ? "100px" : "auto",
            },
          })
        : _c("div", { staticClass: "placeholder-container" }, [
            _c("span", { staticStyle: { display: "block", margin: "auto" } }, [
              _c("small", [_vm._v("Image coming soon")]),
              _c("br"),
            ]),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }