var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-select", {
    staticStyle: {
      "border-top-right-radius": "0px",
      "border-bottom-right-radius": "0px",
      "border-right": "0px",
    },
    attrs: {
      label: "Search Filters",
      items: _vm.filters,
      "item-text": "name",
      "item-value": "key",
      "background-color": "input",
      "menu-props": { offsetY: true, maxHeight: "600" },
      multiple: "",
      outlined: "",
      "hide-details": "",
      "small-chips": "",
      dense: "",
    },
    scopedSlots: _vm._u([
      {
        key: "selection",
        fn: function ({ attrs, item }) {
          return [
            _c(
              "v-chip",
              _vm._b({ attrs: { small: "" } }, "v-chip", attrs, false),
              [
                _vm._v(" " + _vm._s(_vm.getFilterText(item)) + " "),
                _c(
                  "v-icon",
                  {
                    attrs: { small: "", right: "" },
                    on: {
                      click: function ($event) {
                        return _vm.removeFilter(item.key, true)
                      },
                    },
                  },
                  [_vm._v(" $delete ")]
                ),
              ],
              1
            ),
          ]
        },
      },
      {
        key: "item",
        fn: function ({ item, attrs }) {
          return [
            _c(
              "v-list-item",
              [
                _c(
                  "v-list-item-content",
                  [
                    item.key === "source"
                      ? _c("v-autocomplete", {
                          attrs: {
                            items: _vm.sources,
                            "item-text": "source_name",
                            "item-value": "source_id",
                            label: item.name,
                            "hide-details": "",
                            "background-color": "input",
                            dense: "",
                            clearable: "",
                            outlined: "",
                          },
                          on: {
                            change: function ($event) {
                              return _vm.handleInputValue(item)
                            },
                            "click:clear": function ($event) {
                              return _vm.removeFilter(item.key)
                            },
                          },
                          model: {
                            value: _vm.params.source,
                            callback: function ($$v) {
                              _vm.$set(_vm.params, "source", $$v)
                            },
                            expression: "params.source",
                          },
                        })
                      : item.type === "boolean"
                      ? _c("v-checkbox", {
                          staticClass: "pl-2 pb-0 mt-0",
                          attrs: { label: item.name },
                          on: {
                            change: function ($event) {
                              return _vm.handleInputValue(item)
                            },
                          },
                          model: {
                            value: _vm.params[`${item.key}`],
                            callback: function ($$v) {
                              _vm.$set(_vm.params, `${item.key}`, $$v)
                            },
                            expression: "params[`${item.key}`]",
                          },
                        })
                      : _c("v-text-field", {
                          attrs: {
                            label: item.name,
                            "hide-details": "",
                            "background-color": "input",
                            prefix: item.prefix || null,
                            type: item.type,
                            dense: "",
                            clearable: "",
                            outlined: "",
                          },
                          on: {
                            keyup: function ($event) {
                              $event.stopPropagation()
                              attrs.inputValue
                                ? null
                                : _vm.handleInputValue(item)
                            },
                            "click:clear": function ($event) {
                              return _vm.removeFilter(item.key)
                            },
                          },
                          model: {
                            value: _vm.params[`${item.key}`],
                            callback: function ($$v) {
                              _vm.$set(_vm.params, `${item.key}`, $$v)
                            },
                            expression: "params[`${item.key}`]",
                          },
                        }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
      },
    ]),
    model: {
      value: _vm.selectedFilters,
      callback: function ($$v) {
        _vm.selectedFilters = $$v
      },
      expression: "selectedFilters",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }