const host = window.location.hostname

// jbg tenant config
const jbg = {
    hostId: 'jbg',
    domain: process.env.VUE_APP_JBG_DOMAIN,
    clientId: process.env.VUE_APP_JBG_CLIENT_ID
}

const tenantConfigs = []

function getTenantSettings () {
  // check current url hostname for tenant identifiers
  for (const config of tenantConfigs) {
    if (host.includes(`${config.hostId}`)) {
      return config
    }
  }
  // default to JBG tenant
  return jbg
}

const { domain, clientId } = getTenantSettings()
const audience = process.env.VUE_APP_API_AUDIENCE

export { domain, clientId, audience }
