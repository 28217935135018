<template>
    <v-snackbar top v-model="alert" content-class="pa-0" :color="alertType" :timeout="timeout">
        <v-alert dense class="my-0" border="left" dismissible prominent :type="alertType" v-model="alert"
            :retain-focus="true">
            {{ alertMessage }}
            <div class="errorItem" v-for="(message, i) in messages" :key="i">
                {{ message }}
            </div>
        </v-alert>
    </v-snackbar>
</template>
  
<script lang="js">
// mixins
import { displayAlert } from '@/mixins/alert'

export default {
    name: 'AlertBar',
    created() {
        this.$root.$on('alertdialog', (data) => {
            this.alert = data.alert
            this.alertType = data.alertType
            this.alertMessage = data.alertMessage
            this.messages = data.messages

            if (data.timeout) {
                this.timeout = 3000
            }

            window.setTimeout(() => {
                this.timeout = -1
            }, 3100)
        })
    },
    mixins: [displayAlert]
}
</script>
  
<style scoped lang="scss"></style>
  