import item from '@/api/item'

const state = {
  sources: []
}

const getters = {
  sources (state) {
    return state.sources
  },
  getSourceName: getterState => source_id => {
    const match = getterState.sources.find(s => s && s.source_id === source_id)
    return match ? match.source_name : source_id
  }
}

const mutations = {
  setSources (state, payload) {
    state.sources = payload
  }
}

const actions = {
  async setSources (context) {
    if (context.getters.sources.length === 0) {
      let sourcesArr = []
      try {
        const { data } = await item.getSources()
        if (data && data.length > 0) {
          sourcesArr = data
        }
        context.commit('setSources', sourcesArr)
      } catch (err) {
        console.error(err)
      }
    }
  }
}

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations
}
