<template>
  <v-container class="ma-0 pa-0" fluid>
    <v-card>
    <v-tabs color='primary' centered grow v-model="tab">
      <v-tab
        v-for="item in tabs"
        :key="item.tab">
        <v-icon left>{{ item.icon }}</v-icon>
        {{ item.title }}
      </v-tab>
      <v-tabs-items style="border-top: 1px solid #ddd" v-model='tab'>
        <v-tab-item
          v-for="item in tabs"
          :key="item.tab">
          <component :is='item.content'></component>
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
    </v-card>
  </v-container>
</template>
<script>
import ProductsTable from '@/components/products/ProductsTable.vue'
const ProductEvents = () => import('@/components/product-events/ProductEvents.vue')
export default {
  name: 'AdminView',
  data() {
    return {
      tab: 0,
      tabs: [
        {
          tab: 'products',
          title: 'All Products',
          content: ProductsTable,
          icon: 'mdi-package',
          data: []
        },
        {
          tab: 'events',
          title: 'Product Events',
          content: ProductEvents,
          icon: 'mdi-calendar',
          data: []
        }
      ]
    }
  },
  components: { ProductsTable, ProductEvents },
  created () {
    if (this.$route.params.tab) {
      this.tab = this.tabs.findIndex((data) => {
        return data.tab === this.$route.params.tab
      })
    }
  }
}
</script>
  