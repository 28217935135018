<template>
  <v-card
    max-height="520"
    hover
    class="my-card pt-4 my-2 mx-2">
    <section @click.stop="showInfo = true">
      <v-row justify="center">
        <v-col>
          <ProductImage :product="product" small />
        </v-col>
      </v-row>
      <v-row dense justify="center">
        <v-col>
          <v-card-subtitle
            class="caption font-weight-bold text-center text-truncate">
            {{ product.description }}
          </v-card-subtitle>
          <v-card-text class="pb-1">
            <v-divider class="mb-2" />
            <v-row dense wrap justify="center">
              <v-col
                cols="6"
                v-for="(field, idx) in dataFields"
                :key="idx"
                align="center">
                <span class="productInfo font-weight-bold">
                  {{ field.text }}
                </span>
                <br />
                <span class="caption" v-if="field.value === 'pack_size'">
                  {{ product.qty_per_case }} / {{ product.pack_size }}
                </span>
                <span class="caption" v-else>
                  {{ field.prefix }}
                  {{
                    field.type === 'currency'
                      ? `${$config.formatCurrency(product[`${field.value}`])}`
                      : product[`${field.value}`]
                  }}
                </span>
              </v-col>
            </v-row>
          </v-card-text>
        </v-col>
      </v-row>
    </section>
    <v-card-actions class="pb-4">
      <v-spacer />
      <v-btn
        @click="add(product)"
        small
        rounded
        color="#2589BD"
        class="white--text"
        elevation="0"
        :disabled="loading || orderingDisabled">
        add to cart
      </v-btn>
      <v-spacer />
    </v-card-actions>
    <ProductInfo
      v-if="showInfo" 
      v-model="showInfo"
      :product="product"
      :loading="loading"
      :disabled="loading || orderingDisabled"
      :quantity.sync="quantity"
      @close="showInfo = false"
      @add="add">
    </ProductInfo>
  </v-card>
</template>
<script>
import ProductImage from '@/components/products/ProductImage.vue'
const ProductInfo = () => import('@/components/products/ProductInfo.vue')
export default {
  data() {
    return {
      showInfo: false,
      quantity: 1
    }
  },
  name: 'ProductCard',
  components: { ProductInfo, ProductImage }, 
  props: {
    product: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    },
    orderingDisabled: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    dataFields() {
      const fields = [
        { text: 'UPC', value: 'upc' },
        { text: 'Item ID', value: 'item_id' },
        { text: 'Pack Size', value: 'pack_size' },
        { text: 'Cost', value: 'cost', prefix: '$', type: 'currency' }
      ]
      if (this.product?.seasonal) {
        fields.push({
          text: 'Unit Cost',
          value: 'sale_price',
          prefix: '$',
          type: 'currency'
        })
      }
      return fields
    }
  },
  methods: {
    add() {
      this.$emit('add', { product: this.product, quantity: this.quantity })
      this.showInfo = false
    }
  }
}
</script>
<style scoped>
.btnBorder {
  border: solid 1px #e6e6e6;
}
.increase:hover {
  color: green;
}
.decrease:hover {
  color: red;
}
input:focus {
  outline: none;
}
.qtyInput {
  border-top: solid 1px #e6e6e6;
  border-bottom: solid 1px #e6e6e6;
  background: white;
  height: 30px;
  max-width: 75px;
  border-left: hidden;
  border-right: none;
}
.productInfo {
  font-size: 12px;
}
.my-card:hover {
  transition: box-shadow 0.3s ease-in-out;
  box-shadow: 0px 16px 24px -2px rgba(0, 0, 0, 0.4),
    0px 6px 30px 5px rgba(0, 0, 0, 0.12), 0px 8px 10px 5px rgba(0, 0, 0, 0.08);
}
</style>
