<template>
    <div style="border-top: thin solid rgba(0, 0, 0, 0.12); margin-right: 14px;">
      <v-row align="center" dense no-gutters style="padding: 0 0 2px 0; font-size: 12px; height: 58px">
        <v-spacer></v-spacer>
        <div style="margin: auto 34px auto 0; font-size: 12px">
          Rows per page:
        </div>
        <v-col cols="auto" style="margin: 0 22px 0 0; padding: 0">
          <v-select
            style="margin: 0px 0 13px 0; font-size: 12px; width: auto; max-width: 60px"
            hide-details
            :items="pageSizes"
            :value="pageSize"
            @change="$emit('update:pageSize', $event)">
          </v-select>
        </v-col>
        <div style="display: grid; grid-auto-flow: column; grid-auto-columns: auto; align-content: center">
          <div style="font-size: 12px; margin: auto 18px auto 20px">
            Page {{ page }}
          </div>
          <div style="display: grid; grid-auto-flow: column; grid-auto-columns: auto; align-content: center">
            <v-btn icon
              @click="$emit('previous')"
              :disabled="disablePrevious"
              class="mx-2">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
              <v-btn icon
                @click="$emit('next')"
                :disabled="disableNext"
                class="mx-2">
                <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </div>
        </div>
      </v-row>
    </div>
</template>
<script>
export default {
  name: 'PagingFooter',
  props: {
    pageSize: Number,
    pageSizes: Array,
    page: Number,
    disablePrevious: Boolean,
    disableNext: Boolean
  }
}
</script>
  